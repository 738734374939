import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Constant } from '../../core/constant';

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  accessName: string = '';
  modulesData: any = [];
  defaultUrl: string;
  profileData: any;
  permissions: any;
  countObject = {
    pendingBooking: 0,
    teamMessageCount: 0,
    userMessageCount: 0,
  };

  constructor(private http: HttpService, private constant: Constant) {}

  getProfile() {
    return new Promise<any>((resolve, reject) => {
      if (this.modulesData.length == 0 || this.accessName === '') {
        this.http.httpGetWithHeader('profile').subscribe((res: any) => {
          if (res.statusCode === 200) {
            this.profileData = res.data.admin;
            this.countObject = {
              pendingBooking: res.data.pendingBooking,
              teamMessageCount: res.data.teamMessageCount,
              userMessageCount: res.data.userMessageCount
            };

            if (res.data.role?.permissions) {
              this.permissions = [];
              let data = res.data.role.permissions;
              for (let item in data) {
                let body = {
                  value: item,
                  modify: data[item]['modify'],
                  route: data[item]['route'],
                  icon: data[item]['icon'],
                  url: data[item]['url'],
                };
                this.permissions.push(body);
              }
              this.modulesData = this.permissions;
            } else {
              this.modulesData = [
                {
                  value: 'Dashboard',
                  modify: true,
                  route: '/pages/dashboard',
                  url: 'dashboard',
                  icon: '<i class="fa fa-home" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Fans',
                  modify: true,
                  route: '/pages/fans/list/1',
                  url: 'fans',
                  icon: '<i class="fa fa-user" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Teammates',
                  modify: true,
                  route: '/pages/teammate/list/0/1',
                  url: 'teammate',
                  icon: '<i class="fa fa-id-card-o" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Category',
                  modify: true,
                  route: '/pages/category/category/list/1',
                  url: 'category',
                  icon: '<i class="fa fa-bars" aria-hidden="true"></i>',
                },
                // {
                //   value: 'Manage Refferal',
                //   modify: true,
                //   route: '/pages/referal/referal',
                //   url: 'referal',
                //   icon: '<i class="fa fa-code" aria-hidden="true"></i>',
                // },
                {
                  value: 'Manage Zone',
                  modify: true,
                  route: '/pages/geofence/geofence',
                  url: 'geofence',
                  icon: '<i class="fa fa-map-marker" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Booking',
                  modify: true,
                  route: '/pages/booking/booking/0/1',
                  url: 'booking',
                  icon: '<i class="fa fa-newspaper-o" aria-hidden="true"></i>',
                },
                {
                  value: 'Custom Quote',
                  modify: true,
                  route: '/pages/quotes/custom-quote/1',
                  url: 'quotes',
                  icon: '<i class="fa fa-briefcase" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Revenue',
                  modify: true,
                  route: '/pages/revenue/revenue/0/1',
                  url: 'revenue',
                  icon: '<i class="fa fa-usd" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Reports',
                  modify: true,
                  route: '/pages/analytics/analytics',
                  url: 'analytics',
                  icon: '<i class="fa fa-bar-chart" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Notification',
                  modify: true,
                  route: '/pages/notification/notification/list/0',
                  url: 'notification',
                  icon: '<i class="fa fa-bell" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Discount',
                  modify: true,
                  route: '/pages/discount/discount',
                  url: 'discount',
                  icon: '<i class="fa fa-gift" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Certifications',
                  modify: true,
                  route: '/pages/training-compliance/training-compliance',
                  url: 'training-compliance',
                  icon: '<i class="fa fa-file-o" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Requests',
                  modify: true,
                  route: '/pages/requests/requests',
                  url: 'requests',
                  icon: '<i class="fa fa-hdd-o" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Sub-Admin',
                  modify: true,
                  route: '/pages/subadmin/subadmin/0',
                  url: 'subadmin',
                  icon: '<i class="fa fa-universal-access" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage CMS',
                  modify: true,
                  route: '/pages/cms/cms',
                  url: 'cms',
                  icon: '<i class="fa fa-question-circle" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Reviews',
                  modify: true,
                  route: '/pages/review/list/0/1',
                  url: 'review',
                  icon: '<i class="fa fa-star" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Dropdowns',
                  modify: true,
                  route: '/pages/dropdowns/dropdown/0',
                  url: 'dropdowns',
                  icon: '<i class="fa fa-caret-down" aria-hidden="true"></i>',
                },
                {
                  value: 'Manage Graphics',
                  modify: true,
                  route: '/pages/graphics/graphics/page/1',
                  url: 'graphics',
                  icon: '<i class="fa fa-picture-o" aria-hidden="true"></i>',
                },
                // {
                //   value: 'Manage Support',
                //   modify: true,
                //   route: '/pages/support/support',
                //   url: 'support',
                //   icon: '<i class="fa fa-video-camera" aria-hidden="true"></i>',
                // },
              ];
            }

            resolve(true);
          }
        });
      } else {
        resolve(true);
      }
    });
  }
}
