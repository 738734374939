import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth/auth.guard';
import { RoleGuard } from './core/guards/role/role.guard';

const routes: Routes = [

  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [AuthGuard]
  },
  { path: 'pages', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    data: { preload:true },
    canActivate: [AuthGuard,RoleGuard]
  },
  { path:'', pathMatch:"full", redirectTo:'/auth/login'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
