export class apiList {
  login: string = '/admin/login';
  logout: string = '/admin/logout';
  profile: string = '/admin/getProfile';
  updateProfile: string = '/admin/updateProfile';
  changePassword: string = '/admin/changePassword';
  forgotPassword: string = '/admin/forgotPassword';
  vUpload: string = '/admin/ProfilePicUpload';
  getCatandSubcat: string = '/admin/getCatandSubcat';
  getCategory: string = '/admin/getCategory';
  getCategoriesForOther: string = '/admin/getCategoriesForOther';
  addCategory: string = '/admin/addCategory';
  getCategoryById: string = '/admin/getCategoryById';
  editCategory: string = '/admin/editCategory';
  deleteCat: string = '/admin/deleteCat';
  addSubCategory: string = '/admin/addSubCategory';
  getSubCategory: string = '/admin/getSubCategory';
  getSubCategoryById: string = '/admin/getCategoryById';
  editSubCategory: string = '/admin/editSubCategory';
  deleteSubCat: string = '/admin/deleteSubCat';
  randomString: string = '/admin/getRandomString';
  addCoupons: string = '/admin/addCoupons';
  getCoupons: string = '/admin/getCoupons';
  deleteCoupon: string = '/admin/deleteCoupons';
  editCoupon: string = '/admin/editCoupons';
  addRole: string = '/admin/addRole';
  getRole: string = '/admin/getRole';
  editRole: string = '/admin/editRole';
  deleteRole: string = '/admin/deleteRole';
  addSubAdmin: string = '/admin/addSubAdmin';
  getSubAdmin: string = '/admin/getSubAdmin';
  editSubAdmin: string = '/admin/editSubAdmin';
  getStatesOfCountry: string = '/admin/getStatesOfCountry';
  getAllCustomer: string = '/admin/getAllCustomer';
  getAllCustomerWithAddress: string = '/admin/getAllCustomerWithAddress';
  updateCustomer: string = '/admin/updateCustomer';
  getCustomer: string = '/admin/getCustomer';
  getGeoFence: string = '/admin/getGeoFence';
  getGeoFencebyId:string = '/admin/getGeoFencebyId';
  editGeoFence: string = '/admin/editGeoFence';
  createGeofence: string = '/admin/createGeofence';
  deleteGeoFence: string = '/admin/deleteGeoFence';
  addDropDown: string = '/admin/addDropDown';
  getDropDown: string = '/admin/getDropDown';
  getDropDownList:string = '/admin/getDropDownList';
  editDropDown: string = '/admin/editDropDown';
  dropValueAdd: string = '/admin/dropValueAdd';
  getDropValue: string = '/admin/getDropValue';
  deleteValue: string = '/admin/deleteValue';
  getTeamMateById: string = '/admin/getTeamMateById';
  getTeamMateByLocation: string = '/admin/getTeamMateByLocation';
  addTeamMate: string = '/admin/addTeamMate';
  getTeamMates: string = '/admin/getTeamMates';
  getAllTeamMates: string = '/admin/getAllTeamMates';
  updateProfileTeamMate: string = '/admin/updateProfileTeamMate';
  cms: string = '/admin/cms';
  getCertification: string = '/admin/getCertification';
  addCertification: string = '/admin/addCertification';
  editCertification: string = '/admin/editCertification';
  addGraphics:string = '/admin/addGraphics';
  getGraphics: string = '/admin/getGraphics';
  updateGraphics: string = '/admin/updateGraphics';
  editableTime: string = '/admin/editableTime';
  getEditableTime: string = '/admin/getEditableTime';
  getAllBooking: string = '/admin/getAllBooking';
  createBooking: string = '/admin/createBooking';
  editBooking:string = '/admin/editBooking';
  getBookingById: string = '/admin/getBookingById'
  getSlots:string = '/admin/getSlots';
  checkSlots:string = '/admin/checkSlots';
  actionOrder:string = '/admin/actionOrder';
  getAllCustom:string = '/admin/getAllCustom';
  getAllCustomById:string = '/admin/getAllCustomById';
  getTeammateInCustom:string = '/admin/getTeammateInCustom';
  deleteCertification:string = '/admin/deleteCertification';
  getCertificateTeam:string = '/admin/getCertificateTeam';
  reviewRate:string = '/admin/reviewRate';
  dashboard: string = '/admin/dashboard';
  getReports: string = '/admin/getReports';
  getchatByid: string = '/admin/getchatByid';
  getchatByidTeammate:string = '/admin/getchatByidTeammate';
  sendNotification:string = '/admin/sendNotification';
  getNotification:string = '/admin/getNotification';
  getOpt:string = '/admin/getOpt';
  getReminder:string = '/admin/getReminder';
  addReminder:string = '/admin/addReminder';
  updateReminder:string = '/admin/updateReminder';
  revenue:string = '/admin/revenue';
  historyEarning:string = '/admin/historyEarning';
  payout:string = '/admin/payout';
  editCustom:string = '/admin/editCustom';
  getCategoryCustom:string = '/admin/getCategoryCustom';
  createInvoice:string = '/admin/createInvoice';
  notification:string = '/admin/getNotificationHome';
  getCalendarTeammate:string = '/admin/getCalendarTeammate'
}
