import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import {NgxIntlTelInputModule} from "ngx-intl-tel-input";
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrigPlugin from '@fullcalendar/timegrid';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { apiList } from './services/http/api-list';
import { HttpInterceptorService } from './services/interceptor/http-interceptor.service'
import { Constant } from './core/constant';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AgmCoreModule } from '@agm/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
// import { StripeModule } from 'stripe-angular';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,interactionPlugin,timeGrigPlugin
]);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule,
    NgxIntlTelInputModule,
    FullCalendarModule,
    HttpClientModule,
    NgxUiLoaderModule,
    // StripeModule.forRoot(`${environment.stripeApiKey}`),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
      registrationStrategy: "registerWhenStable:30000",
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyByrT6Oft2dXfxphhmN-T22Mr7tUAO8bd8',
      libraries: ['drawing', 'places', 'geometry'],
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    apiList,
    Constant
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
