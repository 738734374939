import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private router:Router
    )
  {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
    ):
       |Observable<boolean
       | UrlTree>
       | Promise<boolean
       | UrlTree>
       | boolean
       | UrlTree {

        if (next.routeConfig.path === 'web') {
          return true;
        } else if (next.routeConfig.path === 'pages') {
          if (localStorage.accessToken !== undefined) {
            return true;
          } else {
            this.router.navigateByUrl('/auth/login');
            return false;
          }
        } else if (next.routeConfig.path === 'auth') {
          if (localStorage.accessToken !== undefined) {
            this.router.navigateByUrl('/pages/dashboard');
            return false;
          } else {
            return true;
          }
        }
  }

}
