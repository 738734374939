import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private router: Router,
    private socket: SocketService
    ) {}

  presentsToast(type: any, position: any, message: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: position,
      timerProgressBar: true,
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton: false,
      timer: 3000,
    });
    Toast.fire({
      title: message,
      icon: type,
    });
  }

  fetchTodayDate() {
    let today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1;
    let yy: any = today.getFullYear();

    if (mm < 10) {
      mm = '0' + mm;
    }
    if (dd < 10) {
      dd = '0' + dd;
    }

    return yy + '-' + mm + '-' + dd;
  }

  setDateToField(value:any){
    let date = new Date(value);
    let dd:any = date.getDate();
    let mm:any = date.getMonth()+1;
    let yy:any = date.getFullYear();

    if (mm < 10) {
      mm = '0' + mm;
    }
    if (dd < 10) {
      dd = '0' + dd;
    }

    return yy + '-' + mm + '-' + dd;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/auth/login']);
    this.socket.close();
  }
}
