import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
  ActivatedRoute,
  NavigationEnd,
} from '@angular/router';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/services/common/common.service';
import { RoleService } from 'src/app/services/role/role.service';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private roleService: RoleService,
    private router: Router,
    private commonService: CommonService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Promise((res) => {
      this.roleService.getProfile().then((data: any) => {
        if (data) {
          let url: any;
          url = state.url.split('/');

          var permissions = this.roleService.modulesData;
          console.log('modulesData', permissions);

          let item = permissions.find((el: any) => el.url === url[2]);

          if (item.modify) {
            res(true);
          } else {
            this.commonService.presentsToast(
              'error',
              'top-end',
              `You don't have permission to access the module`
            );
            this.router.navigate(['auth/login']);
          }
        }
      });
    });
  }
}
