export class Constant {

  /**HTH Work */

  bookingStatus: any = [
    {
      value: 0,
      status: 'PENDING',
    },
    {
      value: 1,
      status: 'ONGOING',
    },
    {
      value: 2,
      status: 'COMPLETED',
    },
    {
      value: 4,
      status: 'REJECTED'
    }
  ];


  /**Droob Status */

  BOOKING_STATUS: any = {
    DEFAULT: 0,
    PENDING: 1,
    ACCEPTED: 2,
    START_PICKUP: 3,
    ARRIVED_AT_PICKED: 4,
    PICKED: 5,
    PICKED_ALL: 6,
    START_DELIVERY: 7,
    COMPLETED: 8,
    SCHEDULED: 9,
    CANCELED: 10,
    ARRIVED_AT_DROP_OFF: 11,
  };
  SHOW_BOOKING_STATUS: any = {
    0: 'Default.',
    1: 'Order created.',
    2: 'Order accepted.',
    3: 'On the way.',
    4: 'Arrived at pickup.',
    5: 'Picked.',
    6: 'Picked all.',
    7: 'On the way to drop-off.',
    8: 'Delivered.',
    9: 'Scheduled.',
    10: 'Canceled.',
    11: 'Arrived at drop off.',
  };
  PAYMENT_STATUS: any = {
    1: 'CASH',
    2: 'CARD',
    3: 'WALLET',
    4: 'K-NET',
  };
  TYPE: any = {
    PERCENTAGE: 0,
    FLAT: 1,
  };
  EMP_TYPE: any = {
    0: 'Salaried',
    1: 'Salaried + commission',
    2: 'Commission',
  };
  PUSH_TYPE_KEYS: any = {
    DEFAULT: 0,
    NEW_BOOKING_DRIVER: 1,
    DRIVER_ACCEPT_DELIVERY: 2,
    DRIVER_ON_THE_WAY_PICKUP: 3,
    DRIVER_ARRIVED_AT_PICKUP: 4,
    STARTED_DELIVERY_BY_DRIVER: 5,
    COMPLETED_DELIVERY_BY_DRIVER: 6,
    CANCEL_DELIVERY_BY_DRIVER: 7,
    CANCEL_BOOKING_BY_CUSTOMER: 8,
    DRIVER_SAY_THANKS: 9,
    REASSIGN_DELIVERY: 10,
    BOOKING_ASSIGN_BY_ADMIN: 11,
    NEW_MESSAGE: 12,
    CANCEL_BOOKING_BY_ADMIN: 13,
    DRIVER_ACCOUNT_APPROVE: 14,
    BOOKING_IS_UPDATED: 15,
    NEW_SUPPORT_MESSAGE: 16,
    UNREAD_MESSAGE_FROM_USER: 17,
    UNREAD_MESSAGE_FROM_SUPPORT: 18,
    SEND_PUSH_NOTIFICATION_BY_ADMIN: 19,
    SEND_MAIL_NOTIFICATION_BY_ADMIN: 20,
  };
  TICKET_TYPE: any = {
    INQUIRY: 1,
    COMPLAIN: 2,
    CHARGES: 3,
  };
  CALL_TYPE: any = {
    INBOUND: 1,
    OUTBOUND: 2,
    LIVE_CHAT: 3,
  };
  SHOW_TICKET_TYPE: any = {
    1: 'Inquery',
    2: 'Complain',
    3: 'Charges',
  };
  SHOW_CALL_TYPE: any = {
    1: 'Inbound',
    2: 'Outbound',
    3: 'Live chat',
  };
  TICKET_STATUS: any = {
    CLOSED: 1,
    ESCALATED: 2,
  };
  SHOW_TICKET_STATUS: any = {
    1: 'Closed',
    2: 'Escalated',
  };
  CLOSING_ISSUES: any = {
    GENERAL_INQUIRY: 1,
    CHECK_ADDRESS: 2,
    FOLLOW_UP_CUSTOMER: 3,
    OTHER: 4,
    UNAVAILABILITY_OF_TRUCKS: 5,
    TECHNICAL_ISSUE: 6,
    MISSING_ITEMS: 7,
    DRIVER_MISTAKE: 8,
    CUSTOMER_FAULT: 9,
    APPLICATION_ISSUE: 10,
    ACCOUNTANT_ISSUE: 11,
    CHNAGE_AMOUNT: 12,
    CANCEL: 13,
  };
  SHOW_CLOSING_ISSUES: any = {
    1: 'GENERAL INQUIRY',
    2: 'CHECK ADDRESS',
    3: 'FOLLOW UP CUSTOMER',
    4: 'OTHER',
    5: 'UNAVAILABILITY OF TRUCKS',
    6: 'TECHNICAL ISSUE',
    7: 'MISSING ITEMS',
    8: 'DRIVER MISTAKE',
    9: 'CUSTOMER FAULT',
    10: 'APPLICATION ISSUE',
    11: 'ACCOUNTANT ISSUE',
    12: 'CHNAGE AMOUNT',
    13: 'CANCEL',
  };
  CHARGE_TYPE: any = {
    ADD: 1,
    REMOVE: 2,
    CANCEL: 3,
  };
  CUSTOMER_CREDIT: any = {
    CREDITS: 1,
    REFUND: 2,
  };
  BOOKINGS_TYPES: any = {
    PENDING: 0,
    UPCOMING: 1,
    ONGOING: 2,
    COMPLETED: 3,
    CANCELLED: 4,
    ALL: 5,
  };
  MODULES: any = [
    {
      isAdd: false,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: false,
      isFilter: false,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/dashboard',
      moduleCode: 2,
      displayName: 'Dashboard',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/users/list',
      moduleCode: 4,
      displayName: 'Manage Users',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/driver/list/0',
      moduleCode: 8,
      displayName: 'Manage Drivers',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 16,
      route: '/pages/category/category',
      displayName: 'Manage Service',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 32,
      route: '/pages/carcategory/carcategory',
      displayName: 'Manage Car Category',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 64,
      route: '/pages/video/video',
      displayName: 'Manage Videos',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 128,
      route: '/pages/geofence/geofence',
      displayName: 'Manage Zone',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/booking/booking/0',
      moduleCode: 512,
      displayName: 'Manage Bookings',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/discount/discount',
      moduleCode: 1024,
      displayName: 'Manage Discounts',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/pricing/pricing',
      moduleCode: 2048,
      displayName: 'Manage Delivery Pricing',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 4096,
      route: '/pages/revenue/revenue/0/1',
      displayName: 'Manage Revenue',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/subadmin/subadmin',
      moduleCode: 8192,
      displayName: 'Manage Sub Admin',
    },
    {
      isAdd: true,
      isEdit: true,
      isView: true,
      isDelete: true,
      isSort: true,
      isFilter: true,
      isSetStatus: true,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/category/vcategory',
      moduleCode: 16384,
      displayName: 'Manage Vehicle',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/walkthrough/walkthrough',
      moduleCode: 32768,
      displayName: 'Manage Walkthrough',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/notification/notification',
      moduleCode: 65536,
      displayName: 'Manage Notifications',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/review/review',
      moduleCode: 131072,
      displayName: 'Manage Reviews',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/analytics/analytics',
      moduleCode: 262144,
      displayName: 'Analytics',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      route: '/pages/cms/cms/0',
      moduleCode: 524288,
      displayName: 'Manage CMS',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 1048576,
      route: '/pages/settings/setting',
      displayName: 'Settings',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 2097152,
      route: '/pages/banners/list',
      displayName: 'Banners',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 4194304,
      route: '/pages/reports/list',
      displayName: 'Manage Reports',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 8388608,
      route: '/pages/support/support',
      displayName: 'Manage Support',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 16777216,
      route: '/pages/refund',
      displayName: 'Refund History',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 33554432,
      route: '/pages/credit',
      displayName: 'Credit History',
    },
    {
      isAdd: true,
      isEdit: false,
      isView: true,
      isDelete: false,
      isSort: true,
      isFilter: true,
      isSetStatus: false,
      isBlocked: false,
      isDeleted: false,
      moduleCode: 67108864,
      route: '/pages/externalFleet/externalFleet',
      displayName: 'Manage External Fleet',
    },
  ];
  CMS: any = {

  }
}
