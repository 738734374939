// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseUrl: 'https://demo.appdukaan.com:4058/api/v1',
  imageUrl: 'https://demo.appdukaan.com:4058/',
  socketUrl: 'https://demo.appdukaan.com:4058',
  stripeApiKey:
    'pk_test_51IK135Iyf6pu1gMeL1LRv1C5ZDdlYpmGjBcT2wege4IXH94g1T4Zx4Zgggz3vs59aDqigMxJb690Me0aAtmNyog200PmCiOWb4',
  firebase: {
    apiKey: 'AIzaSyAAL1r_g17YTpn1YJhzwgd_oMPRUA2RV2M',
    authDomain: 'hth-services-933c2.firebaseapp.com',
    projectId: 'hth-services-933c2',
    storageBucket: 'hth-services-933c2.appspot.com',
    messagingSenderId: '765355890592',
    appId: '1:765355890592:web:965dee9bc99b325ebb36c5',
    measurementId: 'G-EREDWHDE04',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
